var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"addCsdModal","hide-footer":"","size":"lg","centered":"","no-close-on-backdrop":""}},[_c('validation-observer',{ref:"addCsdRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit()}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Agregar Certificado de Sello Digital")])])]),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"RFC","label-for":"csd-tax-id"}},[_c('validation-provider',{attrs:{"name":"taxId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"RFC","name":"taxId"},model:{value:(_vm.csd.taxId),callback:function ($$v) {_vm.$set(_vm.csd, "taxId", $$v)},expression:"csd.taxId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Contraseña","label-for":"csd-password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"Password","placeholder":"Contraseña"},model:{value:(_vm.csd.password),callback:function ($$v) {_vm.$set(_vm.csd, "password", $$v)},expression:"csd.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre legal","label-for":"csd-issuer-name"}},[_c('validation-provider',{attrs:{"name":"Legal Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"IssuerName","placeholder":"Nombre legal"},model:{value:(_vm.csd.issuerName),callback:function ($$v) {_vm.$set(_vm.csd, "issuerName", $$v)},expression:"csd.issuerName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Regimen fiscal","label-for":"csd-fiscal-regime"}},[_c('validation-provider',{attrs:{"name":"FiscalRegime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"h-10",attrs:{"options":_vm.taxRegimes,"label":"name"},model:{value:(_vm.csd.fiscalRegime),callback:function ($$v) {_vm.$set(_vm.csd, "fiscalRegime", $$v)},expression:"csd.fiscalRegime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Certificado","label-for":"csd-certificate"}},[_c('validation-provider',{attrs:{"name":"Certificate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false:null,"name":"Certificate","placeholder":"Certificado"},model:{value:(_vm.csd.certificate),callback:function ($$v) {_vm.$set(_vm.csd, "certificate", $$v)},expression:"csd.certificate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Llave del certificado","label-for":"csd-key"}},[_c('validation-provider',{attrs:{"name":"Key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false:null,"name":"Key","placeholder":"Llave"},model:{value:(_vm.csd.key),callback:function ($$v) {_vm.$set(_vm.csd, "key", $$v)},expression:"csd.key"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"danger","type":"submit","disabled":invalid}},[_vm._v(" Registrar CSD ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-warning","type":"reset","to":{ name: 'home' }}},[_vm._v(" Cancelar ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }