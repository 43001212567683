<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-between"
          >
            <h2>Gestionar Certificados de Sello Digital</h2>
          </b-col>
          <b-col
            cols="auto"
            class="d-flex align-items-center ml-auto"  
          >
            <b-button
              class="add-button-rounded"
              variant="success"
              @click="$bvModal.show('addCsdModal')"
            >
              <feather-icon
                icon="PlusIcon"
                size="18"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row
          class="mt-1"
        >
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h6>Los certificados de sello digital (CSD) te permiten firmar una factura. Recuerda que CSD no es lo mismo que tu FIEL</h6>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        :items="csds"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(active_status)="data">
          <b-badge
            :variant="data.item.active_status ? 'success' : 'danger'"
            size="sm"
          >
            {{ data.item.active_status ? 'Activo' : 'Inactivo' }}
          </b-badge>
        </template>

        <template #cell(assigned_stores)="data"> 
          <div class="d-flex options-flex">
            <div v-for="(store, index) in data.item.assigned_stores" :key="index">
              <b-badge
                pill
                :variant="'light-info'"
              >
              {{ store.name }}
              </b-badge>
            </div>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="handleStatus(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ data.item.active_status ? "Desactivar" : "Activar" }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="handleCsdStoresModal(data.item)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">
                Editar tiendas asignadas
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="handleDeleteCsd(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
            
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ pagination.total_objects }} de {{ csdsCount }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="pagination.page"
              :total-rows="pagination.total_objects"
              :per-page="pagination.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="(value)=>{fetchCsds({ 
                by_establishment: $route.params.id,
                meta: { pagination: {page: value, per_page: pagination.per_page} } 
                })
              }"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <add-csd-modal />
    <b-modal id="bv-modal-edit-csd-stores" hide-footer no-close-on-backdrop>
      <div v-if="csd"> 
        <h4 class="text-center mb-2">Tiendas anexadas</h4>
    
        <div class="d-flex assigned-stores">
          <v-select
            v-model="selectedStore"
            :options="stores"
            label="name"
            class="h-10 w-40"
            name="FiscalRegime"
          />
          <b-button variant="outline-success" @click="handleAttachStore(selectedStore)" :disabled="invalid">
            <feather-icon icon="PlusIcon" />
          </b-button>
        </div>
    
        <div v-for="(store, index) in csd.assigned_stores" :key="index" class="d-flex align-items-center justify-content-between mt-1">
          <b-badge
            pill
            :variant="'light-info'"
          >
          {{ store.name }}
          </b-badge>
          <b-button size="sm" variant="danger" @click="handleAttachStore(store)" class="delete-button">
            <feather-icon icon="TrashIcon"/>
          </b-button>
        </div> 
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddCsdModal from './AddCsdModal.vue'
import vSelect from 'vue-select'

export default {
  components: {
    AddCsdModal,
    vSelect,
  },
  data() {
    return {
      fields: [
        {
          key: 'tax_id',
          label: 'RFC',
          sortable: true,
        },
        {
          key: 'issuer_name',
          label: 'Nombre del emisor',
          sortable: true,
        },
        {
          key: 'fiscal_regime',
          label: 'Regimen Fiscal',
          sortable: true,
        },
        {
          key: 'active_status',
          label: 'Estatus',
          sortable: true,
        },
        {
          key: 'assigned_stores',
          label: 'Tiendas asignadas',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Herramientas',
        },
      ],
      csd: {},
      selectedStore: null,
    }
  },
  computed: {
    ...mapGetters('csds', ['csds', 'csdsCount', 'pagination']),
    ...mapGetters('stores', ['stores']),

    invalid() {
      return !this.selectedStore
    },
  },
  beforeMount() {
    this.fetchCsds({
      by_establishment: this.$route.params.id,
    })
  },
  methods: {
    ...mapActions('csds', ['fetchCsds', 'registerCsd', 'deleteCsd', 'attachCsd', 'deattachCsd', 'editCsd']),
    ...mapActions('stores', ['fetchStores']),
    handleStatus(item) {
      const isActive = item.active_status
      this.$bvModal.msgBoxConfirm(`Por favor confirma que deseas ${isActive ? "desactivar" : "activar"} el Certificado de Sello Digital seleccionado`, {
        title: 'Mensaje de confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            this.editCsd({
              id: item.id,
              csd_tax_information: {
                active_status: isActive ? 'inactive' : 'active',
                store_id: this.$route.params.store_id,
              }
            })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Estado actualizado',
                    icon: 'Trash2Icon',
                    variant: 'success',
                  },
                })
                this.fetchCsds({
                  by_establishment: this.$route.params.id,
                })
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error al actualizar estado',
                    icon: 'XOctagonIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    handleAttachStore(store) {
      const isAssigned = this.csd.assigned_stores.some(s => s.id === store.id)
      this.$bvModal.msgBoxConfirm(`Por favor confirma que deseas ${isAssigned ? 'eliminar' : 'activar' } el uso del Certificado de Sello Digital para la tienda seleccionada`, {
        title: 'Mensaje de confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            if(isAssigned){
              this.deattachCsd({
                store_id: store.id,
                csd_id: this.csd.id,
              }).then(() => {

                this.$bvModal.hide('bv-modal-edit-csd-stores')

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'CSD desanexado',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
          
                this.fetchCsds({
                  by_establishment: this.$route.params.id,
                }).then(res => {
                  this.csd = res.data.find(c => c.id === this.csd.id)
                  
                  this.fetchStores({
                    by_establishment: this.$route.params.id,
                    by_not_csd: this.csd.id,
                    meta: {pagination: {page: 1, per_page: 100000}}
                  })
                })
              }).catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error al desanexar CSD',
                    icon: 'XOctagonIcon',
                    variant: 'danger',
                  },
                })
              })
              return
            }

            this.attachCsd({
              store_tax_information: {
                store_id: store.id,
                csd_tax_information_id: this.csd.id,
              }
            }).then(() => {
              this.$bvModal.hide('bv-modal-edit-csd-stores')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'CSD anexado',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.fetchCsds({
                by_establishment: this.$route.params.id,
              }).then(res => {
                this.csd = res.data.find(c => c.id === this.csd.id)
                this.fetchStores({
                  by_establishment: this.$route.params.id,
                  by_not_csd: this.csd.id,
                  meta: {pagination: {page: 1, per_page: 100000}}
                })
              })
            }).catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error al anexar CSD',
                  icon: 'XOctagonIcon',
                  variant: 'danger',
                },
              })
            })
          }
        })
    },
    handleDeleteCsd(csd){
      this.$swal({
        title: '¿Estas seguro de eliminar?',
        text: 'Esta acción no se puede revertir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar!',
        cancelButtonText: 'No, cancelar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteCsd(csd).then(res => {
            this.$swal({
              title: 'Csd eliminado!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.fetchCsds({
              by_establishment: this.$route.params.id,
            })
          }).catch(err => {
            const errorMessage = err.response.data
            const formattedErrors = Object.keys(errorMessage).map(key => {
              return errorMessage[key][0]
            })
            this.$swal({
              title: formattedErrors.join(', '),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    },
    handleCsdStoresModal(csd){
      this.csd = csd
      this.fetchStores({
        by_establishment: this.$route.params.id,
        by_not_csd: csd.id,
        meta: {pagination: {page: 1, per_page: 100000}}
      })
      this.$bvModal.show('bv-modal-edit-csd-stores')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.add-button-rounded {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button{
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.options-flex{
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.assigned-stores{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  .v-select{
    width: 90%;
  }
}


</style>
