<template>
  <b-modal
    id="addCsdModal"
    hide-footer
    size="lg" 
    centered
    no-close-on-backdrop
    >
    <validation-observer
      ref="addCsdRules"
      v-slot="{ invalid }"
    >
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit()"
      >
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              <h2 class="mb-4">Agregar Certificado de Sello Digital</h2>
            </div>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="RFC"
              label-for="csd-tax-id"
            >
              <validation-provider
                #default="{ errors }"
                name="taxId"
                rules="required"
              >
                <b-form-input
                  v-model="csd.taxId"
                  :state="errors.length > 0 ? false:null"
                  placeholder="RFC"
                  name="taxId"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Contraseña"
              label-for="csd-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-form-input
                  v-model="csd.password"
                  :state="errors.length > 0 ? false:null"
                  name="Password"
                  placeholder="Contraseña"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Nombre legal"
              label-for="csd-issuer-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Legal Name"
                rules="required"
              >
                <b-form-input
                  v-model="csd.issuerName"
                  :state="errors.length > 0 ? false:null"
                  name="IssuerName"
                  placeholder="Nombre legal"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Regimen fiscal"
              label-for="csd-fiscal-regime"
            >
              <validation-provider
                #default="{ errors }"
                name="FiscalRegime"
                rules="required"
              >
                <v-select
                  v-model="csd.fiscalRegime"
                  :options="taxRegimes"
                  label="name"
                  class="h-10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Certificado"
              label-for="csd-certificate"
            >
              <validation-provider
                #default="{ errors }"
                name="Certificate"
                rules="required"
              >
                <b-form-file
                  v-model="csd.certificate"
                  :state="errors.length > 0 ? false:null"
                  name="Certificate"
                  placeholder="Certificado"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Llave del certificado"
              label-for="csd-key"
            >
              <validation-provider
                #default="{ errors }"
                name="Key"
                rules="required"
              >
                <b-form-file
                  v-model="csd.key"
                  :state="errors.length > 0 ? false:null"
                  name="Key"
                  placeholder="Llave"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mt-2 mr-1"
              type="submit"
              :disabled="invalid"
            >
              Registrar CSD
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-warning"
              type="reset"
              class="mt-2"
              :to="{ name: 'home' }"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, zipCode } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      required,
      email,
      zipCode,
      csd:{
        certificate: null,
        key: null,
        taxId: '',
        password: '',
        issuerName: '',
        fiscalRegime: ''
      },
      certificate64: null,
      key64: null
    }
  },
  beforeMount() {
    if(!this.taxRegimes.length){
      this.fetchTaxRegimes()
    }
  },
  computed: {
    ...mapGetters('catalogsSat', ['taxRegimes'])
  },
  methods: {
    ...mapActions('csds', ['registerCsd', 'fetchCsds']),
    ...mapActions('catalogsSat', ['fetchTaxRegimes']),
    handleSubmit() {
      this.$refs.addCsdRules.validate().then(success => {     
        if (success) {
          const certificateReader = new FileReader();
          let certificateBase64 = null;
          certificateReader.onload = (e) => {
            certificateBase64 = certificateReader.result.split(',')[1];
          };
          certificateReader.readAsDataURL(this.csd.certificate);
   
          const keyReader = new FileReader();
          let keyBase64 = null;
          keyReader.onload = (e) => {
            keyBase64 = keyReader.result.split(',')[1];
            this.$swal({
            title: 'Agregando CSD...',
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            },
          })
            this.registerCsd({
              csd_tax_information: {
                tax_id: this.csd.taxId,
                password: this.csd.password,
                issuer_name: this.csd.issuerName,
                fiscal_regime: this.csd.fiscalRegime.value,
                certificate: certificateBase64,
                key: keyBase64,
              }
            })
            .then(res => {
              this.$swal.close()
              this.$swal({
                title: 'CSD agregado!',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.fetchCsds({
                by_establishment: this.$route.params.establishment_id,
                by_store: this.$route.params.store_id,
              })
            })
            .catch(err => {
              this.$swal.close()
                const errorMessage = err.response.data.messages
                const formattedErrors = Object.keys(errorMessage).map(key => {
                  return errorMessage[key][0]
                })
              this.$swal({
                  title: formattedErrors ? formattedErrors[0] : 'Error al agregar beneficiario',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }).finally(() => {
                this.$bvModal.hide('addCsdModal')
              })
            };
          keyReader.readAsDataURL(this.csd.key)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .add-button-rounded {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>